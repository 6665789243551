import { AnchorProvider } from '@project-serum/anchor';
import { ConfirmOptions, Connection, clusterApiUrl, ConnectionConfig } from '@solana/web3.js';

const URL = process.env.REACT_APP_RPC_URL ?? clusterApiUrl("devnet");
const DEFAULT_TIMEOUT_IN_MS = 120000;
const opts = {
  preflightCommitment: "confirmed",
  commitment: "confirmed"
};

export const ProviderTypes = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  FREE: 'free'
}

const getRpcUrl = (type: string) => {
  switch (type) {
    case ProviderTypes.PRIMARY:
      return process.env.REACT_APP_PRIMARY_RPC_URL;
    case ProviderTypes.SECONDARY:
      return process.env.REACT_APP_SECONDARY_RPC_URL;
    default:
      return process.env.REACT_APP_FREE_RPC_URL;
  }
}

export function getSolanaProvider(
  wallet: any,
  type: string = ProviderTypes.FREE,
  commitment: any = opts.commitment,
  preflightCommitment: any = opts.preflightCommitment
) {
  const options = {
    preflightCommitment,
    commitment,
    confirmTransactionInitialTimeout: DEFAULT_TIMEOUT_IN_MS,
    skipPreflight: true
  };

  const connection = new Connection(getRpcUrl(type) ?? URL, options as ConnectionConfig);
  return new AnchorProvider(connection, wallet, options as ConfirmOptions);
}
