import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { VersionedTransaction } from "@solana/web3.js";
import { useContext, useEffect, useState } from "react";
import { activateCoinOp, getCoinOp, initCoinOp } from "../../api/coin-op.service";
import { AudioContext } from "../../contexts/audio.context";
import { AuthorizationContext } from "../../contexts/auth.context";
import { SideBetsContext } from "../../contexts/side-bets.context";
import { getSolanaProvider, ProviderTypes } from "../../services/solana";
// import { sendAndConfirmSerialized } from "../../services/jito.service";
import { SideBetsGame } from "../../utils/constants";
import { NavTabs, Tabs } from "./constants";

export const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

const useSideBets = () => {
    const wallet = useWallet();
    const { connection } = useConnection();
    const { auth } = useContext(AuthorizationContext);
    const { playBg } = useContext(AudioContext);

    const {
        sideBetsState,
        recentSideBets,
        recentUserSideBets,
        recentUserSideBetsLoading,
        loading,
        fetch,
        fetchRecentUserSideBets,
        setLatestSideBet,
        setLoading
    } = useContext(SideBetsContext);

    const [selectedChoice, setChoice] = useState("L2");
    const [tab, setTab] = useState(Tabs.HEADTAILS);
    const [amount, setAmount] = useState(0.05);
    const [navTab, setNavTab] = useState(NavTabs.SIDE_BETS);

    useEffect(() => {
        if (navTab === NavTabs.HISTORY) {
            fetchRecentUserSideBets();
        }
        // eslint-disable-next-line
    }, [navTab]);


    const sendTransactionDirectly = async (serialized) => {
        const versionedTransaction = VersionedTransaction.deserialize(Buffer.from(serialized, 'base64'));
        const provider = getSolanaProvider(wallet, ProviderTypes.PRIMARY);
        const signature = await wallet.sendTransaction(versionedTransaction, provider.connection);
        // const {
        //     value: { blockhash, lastValidBlockHeight },
        // } = await connection.getLatestBlockhashAndContext();

        try {
            const response = await connection.getSignatureStatuses(
                [signature]
            );
            if (response.err) {
                throw response.err;
            }
        } catch (e) {
            throw e;
        }
        return signature;
    };

    // const sendTransaction = async (serialized) => {
    //     return await sendAndConfirmSerialized(wallet, serialized, connection);
    // };

    const onSubmit = async () => {
        setLoading(true);
        try {
            const { coinOp, serialized } = await initCoinOp(SideBetsGame.Slug, { amount, choice: selectedChoice }, auth?.idToken);
            await sendTransactionDirectly(serialized);

            let maxRetries = 7;
            let attempts = 0;
            let isSuccess;
            while (attempts < maxRetries && !isSuccess) {
                try {
                    const { coinOp: activatedCoinOp } = await activateCoinOp(SideBetsGame.Slug, coinOp.id, auth?.idToken);
                    setLatestSideBet(activatedCoinOp);
                    isSuccess = true;
                }
                catch (e) {
                    attempts++;
                    await sleep(2000);
                }
            }
            if (isSuccess) {
                playBg(72);
            } else {
                setLoading(false);
            }
        } catch (error) {
            // Handle the error here or show a message to the user
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const claimReward = async () => {
        setLoading(true);
        try {
            const { serialized } = await getCoinOp(SideBetsGame.Slug, sideBetsState?.coinOp?.id ?? '', auth?.idToken);
            if (serialized) {
                await sendTransactionDirectly(serialized);
                playBg(71);
                setLatestSideBet(null);
            }
        } catch (error) {
            // Handle the error here or show a message to the user
        }
        setLoading(false);
    }

    const handleSetTab = (tab) => {
        setTab(tab);
        if (tab === Tabs.HEADTAILS) {
            setChoice("H3");
        } else {
            setChoice("W3");
        }
    }

    return {
        selectedChoice,
        setChoice,
        tab,
        setTab: handleSetTab,
        amount,
        setAmount,
        loading,
        setLoading,
        navTab,
        setNavTab,
        onSubmit,
        isActive: sideBetsState?.isActive,
        isPendingReward: sideBetsState?.isPendingReward,
        lastestSideBet: sideBetsState?.coinOp,
        recentSideBets,
        recentUserSideBets,
        fetchLatestSideBet: fetch,
        claimReward,
        recentUserSideBetsLoading
    };
};

export default useSideBets;
