import { get, post } from "../utils/http.util";

const BASE_URL =
	process.env.REACT_APP_PERSONAS_API_URL ??
	"https://dev-api.degenpersonas.com/v1";

export const getProfile = async (walletId: any) => {
	const url = `${BASE_URL}/profiles/${walletId}`;
	const response = await get(url);
	return response?.payload;
};

export const editProfile = async (
	walletId: any,
	profileImageUrl = null,
	nickname = null,
	privateMode = false,
	Authorization = ""
) => {
	const url = `${BASE_URL}/profiles`;
	const response = await post(
		url,
		{ profileImageUrl, nickname, privateMode },
		{ Authorization }
	);
	return response?.data?.payload;
};

export const selfExcludeProfile = async (
	selfExclude: number,
	Authorization = ""
) => {
	const url = `${BASE_URL}/profiles`;
	const response = await post(url, { selfExclude }, { Authorization });
	return response?.data?.payload;
};

export const connectDiscordProfile = async (code: any, Authorization = "") => {
	const url = `${BASE_URL}/profiles/discord/connect`;
	const response = await post(url, { code }, { Authorization });
	return response?.data?.payload;
};
