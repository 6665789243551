import { useLocation } from "react-router-dom";

import { StyleThemeProvider } from "../contexts/style-theme.context";
import { AudioProvider } from "../contexts/audio.context";
import { AuthorizationProvider } from "../contexts/auth.context";
import { CommunityProvider } from "../contexts/community.context";
import { ProfileProvider } from "../contexts/profile.context";
import { GameModeProvider } from "../contexts/game-mode.context";

import ClassicLayout from "./layouts/classic/classic";
import EmptyLayout from "./layouts/empty/empty";
import { UserProvider } from "../contexts/user.context";
import { RiskFreePlaysProvider } from "../contexts/risk-free-plays.context";
import { PowerHoursProvider } from "../contexts/powerhours.context";

const EMPTY_PAGES = [
    '/ukraine',
    '/bug-bounty',
    '/discord',
    '/network-down',
    '/try',
    '/deeplink',
];

const LayoutContainer = () => {
    const location = useLocation();
    return (
        <CommunityProvider>
            <PowerHoursProvider>

                <StyleThemeProvider>
                    <AudioProvider>
                        <AuthorizationProvider>
                            <UserProvider>
                                <ProfileProvider>
                                    <GameModeProvider>
                                        <RiskFreePlaysProvider>
                                            {
                                                EMPTY_PAGES.includes(location?.pathname) &&
                                                <EmptyLayout />
                                            }
                                            {
                                                !EMPTY_PAGES.includes(location?.pathname) &&
                                                <ClassicLayout />
                                            }
                                        </RiskFreePlaysProvider>
                                    </GameModeProvider>
                                </ProfileProvider>
                            </UserProvider>
                        </AuthorizationProvider>
                    </AudioProvider>
                </StyleThemeProvider>
            </PowerHoursProvider>

        </CommunityProvider>
    )
};

export default LayoutContainer;