import { BrowserRouter, Routes, Route } from "react-router-dom";

import Web3Provider from "./providers/web3.provider";

import LayoutContainer from "./layout/layout";
import { Home } from "./pages/home/home";
import { VirtualHome } from "./pages/virtual/virtual";
import { UkrainePage } from "./pages/ukraine/ukraine";
import { DiscordCallback } from "./pages/discord-callback/discord-callback";
import { JackpotRoulettePage } from "./pages/jackpot-roulette/jackpot-roulette";
import { DiscordLink } from "./pages/discord-link/discord-link";
import { NetworkDown } from "./pages/network-down/network-down";
import { LegendaryRaffleGiveaway } from "./pages/legendary-raffle/legendary-raffle";
import { SystemMaintenence } from "./pages/system-maintenence/system-maintenence";
import { Deeplink } from "./pages/deeplink/deeplink";
import { SelfExclude } from "./pages/self-exclude/Self-Exclude";

require("@solana/wallet-adapter-react-ui/styles.css");

const App = () => (
	<Web3Provider>
		<BrowserRouter>
			<script src="https://cdn.tailwindcss.com"></script>

			<Routes>
				<Route element={<LayoutContainer />}>
					<Route path="/" element={<Home />} />
					<Route path="/maintenance" element={<SystemMaintenence />} />
					<Route path="/try" element={<VirtualHome />} />
					<Route path="/ukraine" element={<UkrainePage />} />
					<Route path="/network-down" element={<NetworkDown />} />
					<Route path="/jackpot" element={<JackpotRoulettePage />} />
					<Route path="/dozer-giveaway" element={<LegendaryRaffleGiveaway />} />
					<Route path="/discord" element={<DiscordLink />} />
					<Route path="/discord/oauth" element={<DiscordCallback />} />
					<Route path="/deeplink" element={<Deeplink />} />
					<Route path="/self-exclude" element={<SelfExclude />} />
					<Route path=":referral" element={<Home />} />
				</Route>
			</Routes>
		</BrowserRouter>
	</Web3Provider>
);

export default App;
