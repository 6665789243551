import { createContext, useState } from 'react';
import { ProModeModal } from '../components/modals/ProModeModal';

const PRO_KEY = "pro";
const WHALE_KEY = "_whale";
const PRO_DISCLAIMER = "pro_disclaimer";

interface GameModeContextValue {
  whaleMode: boolean | any,
  proMode: boolean | any,
  toggleWhaleMode(): void;
  toggleProMode(): void;
}

const GameModeContext = createContext<GameModeContextValue>({
  whaleMode: false,
  proMode: false,
  toggleWhaleMode() { },
  toggleProMode() { },
});

const GameModeProvider = (props: any) => {
  const [whaleMode, setWhaleMode] = useState<any>(localStorage.getItem(WHALE_KEY) ? true : false);
  const toggleWhaleMode = () => {
    if (!whaleMode) {
      // localStorage.setItem(WHALE_KEY, `true`);
      setWhaleMode(true);
    } else {
      // localStorage.removeItem(WHALE_KEY);
      setWhaleMode(false);
    }
  };
  const [proMode, setProMode] = useState<any>(localStorage.getItem(PRO_KEY) ? true : false);
  const toggleProMode = () => {
    if (!proMode) {
      if (!localStorage.getItem(PRO_DISCLAIMER)) {
        setShowProModal(true);
      }
      localStorage.setItem(PRO_KEY, `true`);
      localStorage.setItem(PRO_DISCLAIMER, `true`);
      // localStorage.removeItem(WHALE_KEY);
      setWhaleMode(false);
      setProMode(true);
    } else {
      localStorage.removeItem(PRO_KEY);
      setProMode(false);
    }
  };


  const [showProModal, setShowProModal] = useState(false);

  return (
    <div>
      <GameModeContext.Provider value={{ whaleMode, toggleWhaleMode, proMode, toggleProMode }}>
        {props.children}
            {
              showProModal &&
              <ProModeModal
                show={() => setShowProModal(true)}
                onClose={() => setShowProModal(false)}
              />
            }
      </GameModeContext.Provider>
    </div>
  )
};

export { GameModeContext, GameModeProvider };