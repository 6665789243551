import { useWallet } from "@solana/wallet-adapter-react";

import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getCurrentBalance } from "../../api-smart-contracts/dcf";
import { StyleThemeContext } from "../../contexts/style-theme.context";
import { GameModeContext } from "../../contexts/game-mode.context";
import { BASED_COIN, BASED_COIN_INVERTED, constants, MAX_AMOUNT, MIN_AMOUNT, RiskFreePlaysStatus, VALID_AMOUNTS, WHALE_PAGE_AMOUNTS } from "../../utils/constants";
import InsuranceWidget from "../insurance/InsuranceWidget";
// import { JackpotPromo } from "../JackpotPromo";
import { RiskFreeFlipSection } from "../RiskFreeFlipCard";
import { customRound } from "../side-bets/helpers";
import CoinFlipWizardStepOneDefault from "./CoinFlipWizardStepOneDefault";
import CoinFlipWizardStepOneWhale from "./CoinFlipWizardStepOneWhale";
import CoinFlipWizardStepOnePro from "./CoinFlipWizardStepOnePro";

const CoinFlipWizardStepOne = ({
  community,
  style,
  whaleMode,
  proMode,
  currentWinStreak,
  streak,
  price,
  setPrice,
  setSide,
  side,
  loading,
  onNext,
  riskFreePlays,
  openPriorityModal,
  priorityFee
}: any) => {
  const { toggleWhaleMode } = useContext(GameModeContext);
  const { toggleDarkBlack } = useContext(StyleThemeContext);

  const wallet = useWallet();

  const [flipCheck, setFlipCheck] = useState<boolean>(true);
  const [rffMessage, setRffMessage] = useState<string>('');

  useEffect(() => {
    const getBalance = async () => {
      const balance = await getCurrentBalance(wallet);
      if (price * 1.035 > balance) {
        setFlipCheck(false);
      } else {
        setFlipCheck(true);
      }
    };

    const getRFFMessage = () => {
      const activatedRff = riskFreePlays?.find((rff: any) => rff.status === RiskFreePlaysStatus.ACTIVATED);
      if (!activatedRff || (activatedRff && activatedRff.solAmount === price)) {
        setRffMessage('');
      }
      if (activatedRff && (activatedRff.solAmount > price)) {
        setRffMessage(`You'll be reimbursed ${price} SOL if you lose.`);
      }
      if (activatedRff && (activatedRff.solAmount < price)) {
        setRffMessage(`You'll be reimbursed ${activatedRff.solAmount} SOL if you lose.`);
      }
    }

    getBalance();
    getRFFMessage();
  }, [wallet, riskFreePlays, price]);

  const enableWhaleMode = () => {
    toggleWhaleMode();
    toggleDarkBlack();
  };

  const getRFFMessage = () => {
    const activatedRff = riskFreePlays?.find((rff: any) => rff.status === RiskFreePlaysStatus.ACTIVATED);
    if (!activatedRff || (activatedRff && activatedRff.solAmount === price)) {
      setRffMessage('');
    }
    if (activatedRff && (activatedRff.solAmount > price)) {
      setRffMessage(`You will only be reimbursed ${price} SOL if you lose`);
    }
    if (activatedRff && (activatedRff.solAmount < price)) {
      setRffMessage(`You will only be reimbursed ${activatedRff.solAmount} SOL if you lose`);
    }
  }

  const flipCoin = () => {
    if (!proMode) {
      if (!VALID_AMOUNTS.includes(price)) {
        return;
      }

      if (whaleMode && !WHALE_PAGE_AMOUNTS.includes(price)) {
        return;
      }
    }

    if (proMode) {
      if (MIN_AMOUNT > price || price > MAX_AMOUNT) {
        return;
      }
    }

    onNext({
      side,
      amount: price,
    });
  };

  const handleSetPrice = (selectedPrice: number) => {
    const updatedPrice = customRound(price + selectedPrice);
    setPrice(updatedPrice > 32 ? 32 : updatedPrice);
  }

  return (
    <div
      className={`play step1 py-4 py-md-0 ${riskFreePlays?.length > 0 ? ' mt-5' : ''}`}
      onMouseEnter={async () => {
        const balance = await getCurrentBalance(wallet);
        if (price * 1.035 > balance) {
          setFlipCheck(false);
        } else {
          setFlipCheck(true);
        }
        getRFFMessage();
      }}
    >
      <div className="form-signin">
        {/* {
          <JackpotPromo style={style} />
        } */}
        {
          !loading && riskFreePlays?.length > 0 &&
          <RiskFreeFlipSection riskFreePlays={riskFreePlays} style={style} />
        }
        <div>
        </div>
        {streak > 1 && (
          <h3>
            {currentWinStreak > 1 ? 'Congrats!' : 'F.'}
            <br />
            You're on a {streak} {currentWinStreak > 1 ? 'win' : 'lose'} streak
          </h3>
        )}
        {loading && (
          <div className="my-5 my-lg-0 py-5 py-lg-0">
            <div className="cssload-container py-5 py-lg-0">
              <div className="cssload-zenith"></div>
            </div>
          </div>
        )}
        {!loading && (
          <>
            <img
              className="mb-3 logo"
              src={
                proMode ? (style.includes('dark') ? BASED_COIN_INVERTED : BASED_COIN) :
                  (!whaleMode ? community?.coinImageUrl : constants.LOGO.Whale)}
              alt="coin"
              width="128"
              height="128"
            />
            <InsuranceWidget style={style} />
            {
              !proMode && <>
                {!whaleMode && (
                  <>
                    <CoinFlipWizardStepOneDefault
                      loading={loading}
                      community={community}
                      side={side}
                      price={price}
                      setSide={(s: any) => !loading && setSide(s)}
                      setPrice={(p: any) => !loading && setPrice(p)}
                      onDoubleOrNothing={flipCoin}
                      flipCheck={flipCheck}
                      rffMessage={rffMessage}
                    />
                  </>
                )}
                {whaleMode && (
                  <>
                    <CoinFlipWizardStepOneWhale
                      loading={loading}
                      community={community}
                      side={side}
                      price={price}
                      setSide={(s: any) => !loading && setSide(s)}
                      setPrice={(p: any) => !loading && setPrice(p)}
                      onDoubleOrNothing={flipCoin}
                      flipCheck={flipCheck}
                      rffMessage={rffMessage}
                    />
                  </>
                )}
              </>
            }
            {
              proMode && <>
                <CoinFlipWizardStepOnePro
                  loading={loading}
                  side={side}
                  price={price}
                  setSide={(s: any) => !loading && setSide(s)}
                  setPrice={(p: any) => !loading && handleSetPrice(p)}
                  onDoubleOrNothing={flipCoin}
                  clearPrice={() => setPrice(0)}
                  flipCheck={flipCheck}
                  rffMessage={rffMessage}
                />
              </>
            }
            {/* <p className="text-danger p-1 mb-1"><small>Note: Network is very congested. We've increased your priority fees by default for faster processing, which can be customized.</small></p> */}
            <h6 className="mt-3 text-secondary">
              <a
                href="#!"
                onClick={openPriorityModal}
                className="no-decoration"
              >
                PRIORITY:{' '}
                {
                  priorityFee === 0 && <>MARKET</>
                }
                {
                  priorityFee === 0.005 && <>HIGH</>
                }
                {
                  priorityFee === 0.01 && <>TURBO</>
                }
                {
                  ![0, 0.005, 0.01].includes(priorityFee) && <>{priorityFee} SOL</>
                }
              </a>

            </h6>
            {
              !proMode &&
              <h6 className="text-secondary">
                <a
                  href="#!"
                  onClick={(ev) => {
                    ev.preventDefault();
                    enableWhaleMode();
                  }}
                  className="no-decoration"
                >
                  {!whaleMode ? `WHALE` : `NORMAL`} MODE
                </a>
                &nbsp;·
                <Link to="/try" className="ms-sm-2 ms-1 no-decoration">
                  TRY FREE
                </Link>
              </h6>
            }
            {
              proMode &&
              <div className="d-inline-flex">
                <h6 className="text-secondary">
                  <a
                    href="#!"
                    onClick={() => setPrice(0.01)}
                    className="no-decoration"
                  >
                    MIN: 0.01 SOL
                  </a>
                </h6>
                <h6>&nbsp;|&nbsp;</h6>
                <h6 className="text-secondary" >
                  <a
                    href="#!"
                    onClick={() => setPrice(32)}
                    className="no-decoration"
                  >
                    MAX 32 SOL
                  </a>
                </h6>
              </div>
            }
          </>
        )}
      </div>
    </div>
  );
};

export default CoinFlipWizardStepOne;
