import moize from 'moize';
import { Idl, Program, BN, web3 } from '@project-serum/anchor';
import { ComputeBudgetProgram, LAMPORTS_PER_SOL, SYSVAR_INSTRUCTIONS_PUBKEY, Transaction, TransactionInstruction, TransactionMessage, VersionedTransaction } from '@solana/web3.js';

import idl from '../interfaces/idl_dcf_23.json';
import { getSolanaProvider, ProviderTypes } from "../services/solana";
import { INITIALIZER_ID, AUTHORITY_ID, MEMO_ID, COLD_HOUSE_ID } from '../utils/program-constants';

import {
  getDegenCoinFlipDegenerateAccount,
  getDegenCoinFlipHouseState,
  getDegenCoinFlipHouseTreasury,
  getDegenCoinFlipRewardsAccount
} from '../utils/accounts';
import { memoize, shouldVersionTransaction } from '../utils/helpers';
import { sendAndConfirm } from '../services/jito.service';
import { JITO_ENABLED } from '../utils/global';

const IGNOREABLE_AMOUNT = 0.01;

const DEFAULT_PRIORITY_FEE = 0.0001;
const MAX_MARKET_LAMPORTS = 1000000;

const {
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  PublicKey,
} = web3;

let programID: any;
let program: any;
let provider: any;

const init = moize((wallet: any = null, type = ProviderTypes.SECONDARY) => {
  programID = new PublicKey(idl.metadata.address);
  provider = getSolanaProvider(wallet, type);
  program = new Program(idl as Idl, programID, provider);
});

const getProvider = moize(() => {
  if ("solana" in window) {
    const anyWindow: any = window;
    const provider = anyWindow.solana;
    if (provider.isPhantom && provider.isConnected) {
      return provider;
    }
  }
  if ("solflare" in window) {
    const anyWindow: any = window;
    const provider = anyWindow.solflare;
    if (provider.isSolflare && provider.isConnected) {
      return provider;
    }
  }
  window.open("https://phantom.app/", "_blank", "noopener,noreferrer");
});

export const signMessage = async (nonce: string) => {
  const message = `I am signing my one-time nonce: ${nonce}`;
  const provider = getProvider();
  const data = new TextEncoder().encode(message);
  try {
    const signedMessage = await provider?.signMessage(data);
    return signedMessage;
  } catch (err) {
    console.warn(err);
  }
};

export const customSignMessage = async (message: string) => {
  const provider = getProvider();
  const data = new TextEncoder().encode(message);
  return await provider?.signMessage(data);
};

export const initDegeneracy = async (wallet: any = null, amount: any) => {
  init(wallet);

  const [_house_treasury_account_pda] = await getDegenCoinFlipHouseTreasury(
    INITIALIZER_ID, AUTHORITY_ID, COLD_HOUSE_ID
  );

  const [_house_state_account_pda] = await getDegenCoinFlipHouseState(
    INITIALIZER_ID, AUTHORITY_ID, COLD_HOUSE_ID
  );

  try {
    await program.rpc.genesis(
      new BN(amount * LAMPORTS_PER_SOL),
      {
        accounts: {
          initializer: provider.wallet.publicKey,
          authority: AUTHORITY_ID,
          coldHouse: COLD_HOUSE_ID,
          houseTreasury: _house_treasury_account_pda,
          houseState: _house_state_account_pda,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY
        }
      }
    );
  }
  catch (er) {
    console.log(er);
  }

  const houseAmount = await provider.connection.getBalance(_house_treasury_account_pda);
  return houseAmount;
};

export const getRewards = async (wallet: any = null, id: any, amount: any, side: any, priorityFee = DEFAULT_PRIORITY_FEE) => {
  const priorityFeeLamports = (priorityFee ?? DEFAULT_PRIORITY_FEE) * LAMPORTS_PER_SOL;
  init(wallet, ProviderTypes.PRIMARY);

  const [_house_state_account_pda] = await getDegenCoinFlipHouseState(
    INITIALIZER_ID, AUTHORITY_ID, COLD_HOUSE_ID
  );

  const [_rewards_account_pda] = await getDegenCoinFlipRewardsAccount(
    provider.wallet.publicKey, INITIALIZER_ID, AUTHORITY_ID, COLD_HOUSE_ID
  );

  const instruction = await program.instruction.reveal(
    {
      accounts: {
        degenerate: provider.wallet.publicKey,
        initializer: INITIALIZER_ID,
        authority: AUTHORITY_ID,
        coldHouse: COLD_HOUSE_ID,
        houseState: _house_state_account_pda,
        rewardsAccount: _rewards_account_pda,
        systemProgram: SystemProgram.programId,
        instructions: SYSVAR_INSTRUCTIONS_PUBKEY
      },
      instructions: [
        new TransactionInstruction({
          keys: [{ pubkey: provider.wallet.publicKey, isSigner: true, isWritable: true }],
          data: memoize(id, amount, side),
          programId: MEMO_ID
        }),
        ComputeBudgetProgram.setComputeUnitPrice({
          microLamports: priorityFeeLamports + 1
        })
      ]
    }
  );
  const { connection } = provider;
  const { value: { blockhash } } = await connection.getLatestBlockhashAndContext();

  const memoInstruction = new TransactionInstruction({
    keys: [{ pubkey: provider.wallet.publicKey, isSigner: true, isWritable: true }],
    data: memoize(id, amount, side),
    programId: MEMO_ID
  });

  if (!process.env.REACT_APP_RPC_URL?.includes('dev') && JITO_ENABLED) {
    return await sendAndConfirm(provider.wallet, connection, memoInstruction, instruction);
  }

  const computeBudgetInstruction = ComputeBudgetProgram.setComputeUnitPrice({
    microLamports: Math.round(priorityFeeLamports === 0 ? (MAX_MARKET_LAMPORTS + 1) : (priorityFeeLamports + 1))
  });

  const instructions = [memoInstruction, computeBudgetInstruction, instruction];

  const messageV0 = new TransactionMessage({
    payerKey: provider.wallet.publicKey,
    recentBlockhash: blockhash,
    instructions
  }).compileToLegacyMessage();

  const versionedTransaction = new VersionedTransaction(messageV0);
  if (shouldVersionTransaction()) {
    const txes = await provider.sendAll([{ tx: versionedTransaction }]);
    return txes[0];
  } else {
    const serialized = versionedTransaction.serialize();
    const x = Transaction.from(serialized) as any;
    return await provider.send(x);
  }
};

export const rewardExists = async (wallet: any) => {
  init(wallet, ProviderTypes.PRIMARY);

  const [_rewards_account_pda] = await getDegenCoinFlipRewardsAccount(
    provider.wallet.publicKey, INITIALIZER_ID, AUTHORITY_ID, COLD_HOUSE_ID
  );
  const rewardsAmount = await provider.connection.getBalance(_rewards_account_pda, "processed");
  return rewardsAmount >= IGNOREABLE_AMOUNT;
}


export const rewardExistsById = async (walletId: any) => {
  init();

  const [_rewards_account_pda] = await getDegenCoinFlipRewardsAccount(
    new PublicKey(walletId), INITIALIZER_ID, AUTHORITY_ID, COLD_HOUSE_ID
  );
  const rewardsAmount = await provider.connection.getBalance(_rewards_account_pda, "processed");
  return rewardsAmount > 0;
}

export const enableMoreDegeneracy = async (wallet: any = null, amount: any) => {
  init(wallet, ProviderTypes.PRIMARY);

  const [_house_treasury_account_pda] = await getDegenCoinFlipHouseTreasury(
    INITIALIZER_ID, AUTHORITY_ID, COLD_HOUSE_ID
  );

  const [_house_state_account_pda] = await getDegenCoinFlipHouseState(
    INITIALIZER_ID, AUTHORITY_ID, COLD_HOUSE_ID
  );

  await program.rpc.invigorate(
    new BN(amount * LAMPORTS_PER_SOL),
    {
      accounts: {
        initializer: INITIALIZER_ID,
        authority: AUTHORITY_ID,
        coldHouse: COLD_HOUSE_ID,
        payer: provider?.wallet?.publicKey,
        houseTreasury: _house_treasury_account_pda,
        houseState: _house_state_account_pda,
        systemProgram: SystemProgram.programId,
        rent: SYSVAR_RENT_PUBKEY
      }
    }
  )
};

export const getCurrentBalance = async (wallet: any) => {
  init(wallet, ProviderTypes.FREE);
  const balance = await provider.connection.getBalance(provider.wallet.publicKey, "processed");
  return balance / LAMPORTS_PER_SOL;
}

export const getBalanceOfWalletId = async (walletId: any) => {
  init();
  const balance = await provider.connection.getBalance(new PublicKey(walletId), "processed");
  return balance / LAMPORTS_PER_SOL;
}

export const getBalance = async (wallet: any, accountId: any) => {
  init(wallet);
  const balance = await provider.connection.getBalance(accountId, "processed");
  return balance / LAMPORTS_PER_SOL;
}

export const getDegenerateAccountBalance = async (wallet: any) => {
  init(wallet, ProviderTypes.PRIMARY);
  const [_degenerate_account_pda] = await getDegenCoinFlipDegenerateAccount(
    provider.wallet.publicKey, INITIALIZER_ID, AUTHORITY_ID, COLD_HOUSE_ID
  );
  const balance = await provider.connection.getBalance(_degenerate_account_pda, "processed");
  return balance / LAMPORTS_PER_SOL;
}
