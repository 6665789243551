import { Toast, ToastContainer } from "react-bootstrap";
export const CoinFlipRewardFailedToast = ({ code, show, onClose }: any) => {
  return (
    <ToastContainer position="bottom-start" className="p-3 position-fixed">
      <Toast className="" onClose={onClose} show={show} delay={10000} autohide>
        <Toast.Body>
          <div className="d-flex flex-row px-2 py-1">
            <div className="d-flex flex-column w-100">
              <h6 className="mb-0 d-flex">
                CLAIM REWARD FAILED!
              </h6>
              <div className="text-gray mt-1">
                <small>Problems persists?<br />Come to <a href="https://discord.com/invite/degencoinflip" rel="noopener noreferrer" target="_blank" >support</a> with code (<b>{code ?? 'uhoh'}</b>).
                </small>
              </div>
            </div>
          </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};
