import React, { createContext, useContext, useEffect, useState } from 'react';
import { CHALLENGES, PromoBackgrounds, WHALE_MODE_STYLE } from '../utils/constants';
import { CommunityContext } from './community.context';
import { isPromoActive } from '../utils/helpers';

const DEFAULT_COMMUNITY = isPromoActive() ? { ...CHALLENGES[0], ...PromoBackgrounds } : { ...CHALLENGES[0], themes: ["light", "dark"] };
// const DEFAULT_THEME =  isPromoActive() ? 'dark' : 'light';
const DEFAULT_THEME =  'dark';
const STYLE_KEY = '-style-'; // -style to reset cache, _style to keep cache
const DEFAULT_TOGGLE = isPromoActive() ? false : true; // show/hide theme color toggle button

interface StyleThemeContextValue {
  style: string | any;
  canToggle: boolean | any;
  toggleTheme(event: React.ChangeEvent<HTMLInputElement>): void;
  toggleDarkBlack(): void;
  setTheme(theme: any): any;
}

const StyleThemeContext = createContext<StyleThemeContextValue>({
  style: DEFAULT_THEME,
  canToggle: DEFAULT_TOGGLE,
  toggleTheme() { },
  toggleDarkBlack() { },
  setTheme() { }
});

const StyleThemeProvider = (props: any) => {
  // const [style, setStyle] = useState(DEFAULT_THEME);
  const [style, setStyle] = useState(localStorage.getItem(STYLE_KEY) ?? DEFAULT_THEME); // event off
  const [canToggle, setCanToggle] = useState(DEFAULT_TOGGLE);
  const { community } = useContext(CommunityContext);

  useEffect(() => {
    if (community) {
      if (community.slug === DEFAULT_COMMUNITY.slug) return;
      const [PRIMARY_THEME] = community.themes;
      setStyle(PRIMARY_THEME);
      setCanToggle(community?.themes?.length > 1);
    }
  }, [community]);

  const toggleTheme = () => {
    const [PRIMARY_THEME, SECONDARY_THEME] = community.themes;
    if (style === WHALE_MODE_STYLE) {
      localStorage.setItem(STYLE_KEY, PRIMARY_THEME);
      setStyle(PRIMARY_THEME);
    }
    else {
      if (!SECONDARY_THEME) {
        localStorage.setItem(STYLE_KEY, PRIMARY_THEME);
        setStyle(PRIMARY_THEME);
      } else {
        const theme = style === PRIMARY_THEME ? SECONDARY_THEME : PRIMARY_THEME;
        localStorage.setItem(STYLE_KEY, theme);
        setStyle(theme);
      }
    }
  };

  const toggleDarkBlack = () => {
    if (style === WHALE_MODE_STYLE) {
      toggleTheme();
    } else {
      // localStorage.setItem(STYLE_KEY, WHALE_MODE_STYLE);
      setStyle(WHALE_MODE_STYLE);
    }
  };

  const setTheme = (theme: any) => {
    const [PRIMARY_THEME] = theme?.themes;
    localStorage.setItem(STYLE_KEY, PRIMARY_THEME);
    setStyle(PRIMARY_THEME);
  };

  return (
    <div>
      <StyleThemeContext.Provider value={{ style, canToggle, toggleTheme, toggleDarkBlack, setTheme }}>
        {props.children}
      </StyleThemeContext.Provider>
    </div>
  )
};

export { StyleThemeContext, StyleThemeProvider };
