import React, { useContext, useEffect, useState } from "react";
import { StyleThemeContext } from "../../contexts/style-theme.context";
import { AuthorizationContext } from "../../contexts/auth.context";
import { ProfileContext } from "../../contexts/profile.context";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { selfExcludeProfile } from "../../api/profiles.service";

export const SelfExclude = () => {
	const { style } = useContext(StyleThemeContext);
	const wallet: any = useWallet();

	const { auth, signIn, signOut, signInViaLedger } =
		useContext(AuthorizationContext);
	const { profile, fetchProfile } = useContext(ProfileContext);

	const [selfExcludeLength, setSelfExcludeLength] = useState(3);

	const [nextCheckEnabled, setNextCheckEnabled] = useState(true);

	const theme = useContext(StyleThemeContext);

	useEffect(() => {
		if (auth) {
			fetchProfile(auth?.username);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth]);

	const handleSubmit = async () => {
		await selfExcludeProfile(selfExcludeLength, auth?.idToken);

		setTimeout(() => {
			signOut();
			window.location.href = "/";
		}, 5000);
	};

	const [confirmed, setConfirmed] = useState(0);

	const exclusionOptions = [
		{ value: 3, label: "3 Days" },
		{ value: 7, label: "1 Week" },
		{ value: 14, label: "2 Weeks" },
		{ value: 30, label: "1 Month" },
		{ value: 90, label: "3 Months" },
		{ value: 999, label: "Permanent" },
	];

	return (
		<React.Fragment>
			<div className={style}>
				<div className=" body-wrapper h-100vh h-100 main-header">
					<div className="play step mt-md-5 pt-md-5 pt-4">
						<div className="text-center">
							<h3>Self-Exclusion </h3>
							<p>
								Take control of your gambling experience by selecting a
								self-exclusion period that works best for you.
							</p>
							<p className="text-danger">
								By self exlcuding you will lose access to all games, including
								other DCF affilated games (Dozer, Spin, etc), as well as any
								events or promotions during the self-exclusion period.
							</p>
						</div>
						{wallet.connected && auth ? (
							<div className="row mt-5">
								<div className="col-12 col-md-6">
									<div className="mt-4 border rounded p-4 shadow">
										<div className="text-center">
											<h3>Account Info</h3>
											<p className="text-muted">
												Please verify your account information before
												self-excluding.
											</p>
										</div>
										<div className="text-sm-left mt-4">
											<div>
												{profile?.nickname ? (
													<>
														<p>
															<strong>Nickname:</strong> {profile?.nickname}
														</p>
														<p>
															<strong>Wallet ID:</strong> {profile?.walletId}
														</p>
													</>
												) : (
													<p>
														You do not have a nickname set. Please set a
														nickname before self-excluding.
													</p>
												)}
											</div>
										</div>
									</div>
									<div className="mt-4 border rounded p-4 shadow">
										{/* gambling help resources */}
										<h3 className="text-center">Resources</h3>
										<p className="text-muted text-cen">
											If you or someone you know is struggling with problem
											gambling, please reach out to one of the following
											resources.
										</p>
										<div className="text-sm-left mt-4">
											<div>
												<p>
													<strong>Self-Test: </strong>
													<a href="https://www.gambleaware.org/self-assessment-tool-entry">
														Gamble Aware
													</a>
												</p>
												<p>
													<strong>General Resources: </strong>
													<a href="https://www.gambleaware.org/advice-if-you-are-gambling">
														Gamble Aware
													</a>
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-6 p-4">
									<div className="text-center">
										<h5>Time Period</h5>
										<div className="flex justify-content-center row ">
											{exclusionOptions.map((option) => (
												<button
													key={option.value}
													className={`btn m-2 col-sm-3 ${
														selfExcludeLength === option.value
															? `btn-warning btn-outline-warning ${
																	theme.style === "dark"
																		? `text-light`
																		: `text-dark`
															  }`
															: `btn-outline-warning text-light ${
																	theme.style === "dark"
																		? `text-light`
																		: `text-dark`
															  }`
													}`}
													onClick={() => setSelfExcludeLength(option.value)}
												>
													{option.label}
												</button>
											))}
										</div>

										{/* confirmation checking you agree to self exclude for (time), then incirment confirmed, next a second checkbox saying either to remove the self exclusion contact support on (add days to current date)  or for forver just a second box to confirm it cannot be undone*/}
										<div className="text-center mx-4 d-flex justify-content-left align-items-left">
											<input
												type="checkbox"
												onChange={(e) => {
													setNextCheckEnabled(false);
													setConfirmed(e.target.checked ? 1 : 0);
													setTimeout(() => {
														setNextCheckEnabled(true);
													}, 2000);
												}}
											/>
											<label className="p-2">
												I agree to self-exclude for {selfExcludeLength} days.
											</label>
										</div>
										{confirmed >= 1 && (
											<div className="text-center mx-4 d-flex justify-content-left align-items-center">
												<input
													type="checkbox"
													disabled={confirmed === 1 && !nextCheckEnabled}
													onChange={(e) => {
														if (nextCheckEnabled) {
															setNextCheckEnabled(false);
															setConfirmed(e.target.checked ? 2 : 1);
															setTimeout(() => {
																setNextCheckEnabled(true);
															}, 2000);
														}
													}}
												/>
												<label className="p-2 text-danger">
													<>This cannot be undone.</>
												</label>
											</div>
										)}

										{confirmed >= 2 && (
											<div className="text-center mx-4 d-flex justify-content-left align-items-center">
												<input
													type="checkbox"
													disabled={confirmed === 2 && !nextCheckEnabled}
													onChange={(e) => {
														if (nextCheckEnabled) {
															setConfirmed(e.target.checked ? 3 : 2);
															setNextCheckEnabled(false);
															setTimeout(() => {
																setNextCheckEnabled(true);
															}, 2000);
														}
													}}
												/>
												<label className="p-2">
													{selfExcludeLength === 999 ? (
														<>
															<span className="text-danger">
																Last chance to change your mind.
															</span>
														</>
													) : (
														<>
															Contact support on{" "}
															{new Date(
																new Date().getTime() +
																	selfExcludeLength * 24 * 60 * 60 * 1000
															).toLocaleDateString()}{" "}
															re-enable.
														</>
													)}
												</label>
											</div>
										)}
										{confirmed >= 3 && (
											<div className="text-center mt-4 d-flex justify-content-center align-items-center">
												<button
													className="btn btn-primary"
													onClick={handleSubmit}
												>
													Submit
												</button>
											</div>
										)}
									</div>
								</div>
							</div>
						) : (
							<>
								{wallet.connected ? (
									<div className="d-flex justify-content-center align-items-center flex-column">
										<button
											className="btn btn-primary btn-lg"
											onClick={() => signIn(wallet, "")}
										>
											Sign In
										</button>
										<span
											className="cursor-pointer"
											onClick={() => signInViaLedger(wallet)}
										>
											Sign In via Ledger
										</span>
									</div>
								) : (
									<div className="d-flex justify-content-center align-items-center flex-column">
										<WalletMultiButton />
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
