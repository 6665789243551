export const SocialLinks = ({ social }) => {
    return (
        <div className="text-center justify-content-center d-flex">
            <a href={`https://www.coinbase.com/web3/dapps/degen-coin-flip`} target="_blank" rel="noopener noreferrer" className="cursor-pointer me-2"><img src="https://i.imgur.com/PcdfWif.png" className="img-fluid" alt="coinbase" style={{ maxWidth: '28px' }} /></a>
            <a href={social.twitterUrl} target="_blank" rel="noopener noreferrer" className="cursor-pointer me-1"><i className="fab fa-2x fa-twitter text-twitter"></i></a>
            <a href={social.discordUrl} target="_blank" rel="noopener noreferrer" className="cursor-pointer me-1"><i className="fab fa-2x fa-discord text-discord"></i></a>
            <div id="anj-39a70568-0b26-48ee-a12e-50a8315983f7"
                data-anj-seal-id="39a70568-0b26-48ee-a12e-50a8315983f7"
                data-anj-image-size="8"
                data-anj-image-type="basic-small" />
        </div>
    );
}