import { useWallet } from "@solana/wallet-adapter-react";
import React, { useContext, useEffect, useState } from "react";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";

import { getCurrentBalance } from '../../api-smart-contracts/dcf';
import { FlipRow } from "../../components/FlipRow";
import { RecentCoinFlipsContext } from "../../contexts/recent-coin-flips.context";
import { CommunityContext } from "../../contexts/community.context";
import { ProfileModal } from "../../components/modals/ProfileModal";
import { ProfileContext } from "../../contexts/profile.context";
import { StyleThemeContext } from "../../contexts/style-theme.context";
import { TopCoinFlipsContext } from "../../contexts/top-coin-flips.context";
import { FlipRowPlaceholder } from "../../components/FlipRowPlaceholder";
import { AuthorizationContext } from "../../contexts/auth.context";
import { isMobile } from "react-device-detect";
import { VolumeRankingModal } from "../../components/modals/VolumeRankingModal";
import { getActivePromos, getMyFragments } from "../../api/atlantis.service";
import { saveState } from "../../utils/localStorage";
import { FragmentCounter } from "../../components/FragmentCounter";
import { NewRaffleCounter } from "../../components/NewRaffleCounter";
import { ActivePromoIdKey, PROMO_TYPES, UserAccessStatus } from "../../utils/constants";
import { isPromoFlagEnabled } from "../../utils/helpers";
import { DateTime } from "luxon";
import { ScrollCounter } from "../../components/ScrollCounter";
import { VerifyUserModal } from "../../components/modals/VerifyUserModal";
import { PointsCounter } from "../../components/PointsCounter";
// import { AnnouncementsWidget } from "../../components/AnnouncementsWidget";
import { SettingModal } from "../../components/modals/SettingModal";
import { SettingContext } from "../../contexts/setting.context";
import { MyFlipsModal } from "../../components/modals/MyFlipsModal";
// import { SlowTPSModal } from "../../components/modals/SlowTPSModal";

const DEFAULT_INTERVAL = 3000;
// const SLOW_TPS_KEY = "_slowAf";

const MenuBar = () => {
  const wallet: any = useWallet();

  const { auth, signOut } = useContext(AuthorizationContext);
  const { style } = useContext(StyleThemeContext);
  const { recentCoinFlips } = useContext(RecentCoinFlipsContext);
  const { topCoinFlips, fetchTop } = useContext(TopCoinFlipsContext);
  const { community } = useContext(CommunityContext);
  const { profile, fetchProfile, updateProfile } = useContext(ProfileContext);
  const { liveFeed } = useContext(SettingContext);

  const [listType, setListType] = useState<any>();
  const [currentBalance, setCurrentBalance] = useState(0);
  const [rankData] = useState<any>();
  const [rankCache, setRankCache] = useState<any>();
  const [walletCache, setWalletCache] = useState('');
  const [activePromo, setActivePromo] = useState<any>();

  const [showProfileModal, setShowProfileModal] = useState(false);
  const handleProfileModalClose = () => setShowProfileModal(false);
  const handleProfileModalOpen = () => setShowProfileModal(true);

  const [showSettingModal, setSettingModal] = useState(false);
  const handleSettingModalClose = () => setSettingModal(false);
  const handleSettingModalOpen = () => setSettingModal(true);

  const [showRankModal, setShowRankModal] = useState(false);
  const handleRankModalOpen = () => setShowRankModal(true);
  const handleRankModalClose = () => setShowRankModal(false);

  const [showVerifyUserModal, setShowVerifyUserModal] = useState(false);
  const handleVerifyUserModalOpen = () => setShowVerifyUserModal(true);
  const handleVerifyUserModalClose = () => setShowVerifyUserModal(false);

  const [showMyFlipsModal, setShowMyFlipsModal] = useState(false);
  const handleMyFlipsModalOpen = () => setShowMyFlipsModal(true);
  const handleMyFlipsModalClose = () => setShowMyFlipsModal(false);

  // const [showSlowTPSModal, setShowSlowTPSModal] = useState(false);
  // const handleSlowTPSModalOpen = () => setShowSlowTPSModal(true);
  // const handleSlowTPSModalClose = () => setShowSlowTPSModal(false);

  useEffect(() => {
    (async () => {
      if (
        !wallet ||
        !wallet.publicKey ||
        !wallet.connected ||
        wallet.publicKey.toString() === walletCache
      ) {
        return;
      }
      setWalletCache(wallet.publicKey.toString());
      fetchProfile(wallet.publicKey.toString());
    }
    )();
    // eslint-disable-next-line
  }, [wallet]);


  useEffect(() => {
    (async () => {
      const expired = DateTime.fromSeconds(auth?.exp ?? 0) < DateTime.utc().plus({ minutes: 150 });

      if (
        !auth ||
        !wallet.connected ||
        wallet?.publicKey?.toString() !== auth?.username ||
        !!rankData ||
        expired ||
        auth?.username === rankCache
      ) {
        return;
      }
      setRankCache(auth?.username);
      // fetchRankData(auth?.idToken);
      if (isPromoFlagEnabled()) {
        getActivePromo(auth?.idToken);
      }

      if (auth.status !== UserAccessStatus.VERIFIED) {
        handleVerifyUserModalOpen();
      }

      // if (!loadState(SLOW_TPS_KEY)) {
      //   handleSlowTPSModalOpen();
      //   saveState(true, SLOW_TPS_KEY);
      // }

    })();
    // eslint-disable-next-line
  }, [auth, wallet]);


  // const fetchRankData = async (token: string) => {
  //   const rankData = await getMyWeeklyVolumeRank(token);
  //   setRankData(rankData);
  // };

  const getActivePromo = async (token: string) => {
    const promo = await getActivePromos(token);
    console.log(promo);
    if (!promo) {
      setActivePromo(null);
    } else {
      saveState(promo.promoId, ActivePromoIdKey);
      setActivePromo(promo);
      fetchPromo(promo.promoId, token);
    }
  }

  const [fragments, setFragments] = useState<number>(0);

  const fetchPromo = async (promoId: string, token: string) => {
    const {
      availableFragments
    } = await getMyFragments(promoId, token);
    setFragments(availableFragments);
  }

  const BalanceStatusInfo = () => {
    useEffect(() => {
      const interval = setInterval(async () => {
        if (wallet?.publicKey?.toString()) {
          const balance = await getCurrentBalance(wallet);
          setCurrentBalance(balance);
        }
      }, DEFAULT_INTERVAL); // Switch every 3 seconds
      return () => clearInterval(interval); // Clean up the interval
      // eslint-disable-next-line
    }, []);

    return (
      <>
        {
          currentBalance > 0 &&
          <h5 className="mt-1 balance-text mb-0">
            {currentBalance?.toFixed(3)}<span className="ms-1">SOL</span>
          </h5>
        }
      </>
    );
  };

  const RankInfo = () => {
    // useInterval(async () => {
    //   if (auth?.idToken && !rankData) {
    //     fetchRankData(auth?.idToken);
    //   }
    // }, SLOW_INTERVAL);

    return (
      <>
        {
          rankData?.rank > 0 &&
          <>
            {isMobile && <span>&nbsp;·&nbsp;</span>}
            <h6 className="mt-1 balance-text mb-0 cursor-pointer" onClick={handleRankModalOpen}>
              <span className={"me-1 "}>RANK</span>
              {rankData.rank === 1 ? `👑` : `${rankData.rank}`}
            </h6>
          </>
        }
      </>
    );
  };

  const CoinFlipsList = () => {
    // const DEFAULT_INTERVAL = 4000;
    // useInterval(async () => {
    //   fetch();
    // }, DEFAULT_INTERVAL);

    return (
      <ul className="list-group mt-1 leaderboard-list border ms-auto">
        {
          recentCoinFlips?.slice(0, 12)?.map((flip: any) => {
            return (
              <FlipRow
                flip={flip}
                type=''
                key={flip.id}
                defaultProfilePicture={community?.profileImageUrl}></FlipRow>
            )
          })
        }
        {
          !recentCoinFlips?.length && new Array(10).fill(null).map((_, i) => {
            return <FlipRowPlaceholder key={i} n={i} />
          })
        }
      </ul>
    );
  };

  const TopCoinFlipsList = () => {
    return (
      <ul className="list-group mt-1 leaderboard-list border ms-auto">
        {
          topCoinFlips?.slice(0, 12)?.map((flip: any) => {
            return (
              <FlipRow
                flip={flip}
                type=''
                key={flip.id}
                defaultProfilePicture={community?.profileImageUrl}></FlipRow>
            )
          })
        }
        {
          !topCoinFlips?.length && new Array(10).fill(null).map((_, i) => {
            return <FlipRowPlaceholder key={i} n={i} />
          })
        }
      </ul>
    );
  };

  const RecentButton = () => {
    return (
      <a className="ms-sm-2 ms-1 cursor-pointer" href="#!" onClick={ev => ev.preventDefault()}>
        <button className={"btn " + (listType === 'recent' ? "btn-outline-dark" : "btn-dark")} onClick={async () => {
          if (listType !== 'recent') {
            // fetch();
            setListType('recent');
          }
          else setListType(null)
        }}>
          RECENT <i className="d-none d-sm-inline-flex fas fa-caret-down fa-xs"></i>
        </button>
      </a>
    );
  };

  const WinStreaksButton = () => {
    return (
      <a className="ms-sm-2 ms-1 cursor-pointer" href="#!" onClick={ev => ev.preventDefault()}>
        <button className={"btn " + (listType === 'top' ? "btn-outline-dark" : "btn-dark")} onClick={async () => {
          if (listType !== 'top') {
            fetchTop();
            setListType('top');
          }
          else setListType(null)
        }}>
          TOP <span className="d-none d-sm-inline-flex">STREAKS</span> <i className="d-none d-sm-inline-flex fas fa-trophy fa-xs"></i>
        </button>
      </a>
    );
  };

  const MoreButton = () => {
    return (
      <>
        <Dropdown className="ms-sm-2 ms-1">
          <Dropdown.Toggle variant="dark" id="dropdown-basic">
            MORE
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              href="#!" onClick={ev => { ev.preventDefault(); handleRankModalOpen(); }}
              target="_blank" rel="noopener noreferrer">LEAGUE <i className="d-inline-flex fas fa-ranking-star fa-xs"></i></Dropdown.Item>
            <Dropdown.Item
              href={`https://live.degencoinflip.com/`}
              target="_blank" rel="noopener noreferrer">LIVE <i className="d-inline-flex fas fa-external-link-alt fa-xs"></i></Dropdown.Item>
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement="bottom"
              overlay={
                <Popover id="popover-trigger-hover-focus" title="Popover bottom">
                  <img className="" src="https://i.imgur.com/ecVlYa2.png" alt="stats" width="100%" height="100%" />
                </Popover>}
            >
              <Dropdown.Item
                href={`https://mixpanel.com/public/KQMKc744sGRZMq3idvsBmK/`}
                target="_blank" rel="noopener noreferrer">STATS <i className="d-inline-flex fas fa-external-link-alt fa-xs"></i></Dropdown.Item>
            </OverlayTrigger>
            <Dropdown.Item
              href={`https://leaderboard.degencoinflip.com/`}
              target="_blank" rel="noopener noreferrer">LEADERBOARD <i className="d-inline-flex fas fa-external-link-alt fa-xs"></i></Dropdown.Item>
            <Dropdown.Item
              href={`https://shop.degencoinflip.com/`}
              target="_blank" rel="noopener noreferrer">SHOP <i className="d-inline-flex fas fa-external-link-alt fa-xs"></i></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const ProfileButton = () => {
    const CustomDropdownToggle = React.forwardRef(({ onClick }: any, ref: any) => {
      return <>
        <div className="ms-sm-2 ms-1 profile-picture-md" onClick={onClick}>
          <img className={`image rounded-circle cursor-pointer border border-2`}
            src={profile?.profileImageUrl ?? community?.profileImageUrl}
            alt={'pfp'}
            onError={(e: any) => {
              if (e.target.src !== community?.profileImageUrl) { e.target.onerror = null; e.target.src = community?.profileImageUrl; }
            }} />
        </div>
      </>
    });
    return (
      <>
        <Dropdown align={{ sm: isMobile ? "start" : "end" }} className="ms-sm-2 ms-1">
          <Dropdown.Toggle as={CustomDropdownToggle} id="dropdown-basic">
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={handleProfileModalOpen}
              target="_blank" rel="noopener noreferrer">PROFILE</Dropdown.Item>
            {/* <Dropdown.Item
              onClick={handleInventorModalOpen}
              target="_blank" rel="noopener noreferrer">INVENTORY</Dropdown.Item> */}
            <Dropdown.Item onClick={handleMyFlipsModalOpen} target="_blank">MY FLIPS</Dropdown.Item>
            <Dropdown.Item onClick={handleSettingModalOpen} target="_blank">SETTING</Dropdown.Item>
            <Dropdown.Item onClick={() => { wallet.disconnect(); signOut(); }}>DISCONNECT WALLET</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }

  const ButtonsRow = () => {
    return (
      <div className="d-flex flex-row mb-2 toolbar">
        <RecentButton />
        <WinStreaksButton />
        <MoreButton />
        {wallet?.connected && auth?.username === wallet?.publicKey?.toString() && <ProfileButton />}
      </div>
    );
  }

  const CounterInfoRow = () => {

    return (
      <div className={"" + (isMobile ? "d-inline-flex justify-content-center align-items-center" : "")}>
        {wallet?.connected && <BalanceStatusInfo />}
        {
          wallet?.connected && wallet?.publicKey?.toString() === auth?.username &&
          <RankInfo />
        }
        {
          wallet?.connected && wallet?.publicKey?.toString() === auth?.username && <>
            {
              activePromo?.promoType === PROMO_TYPES.FRAGMENT &&
              <>
                {isMobile && <span>&nbsp;·&nbsp;</span>}
                <FragmentCounter
                  authToken={auth?.idToken}
                  walletId={wallet?.publicKey?.toString()}
                  promo={activePromo}
                  style={style} />
              </>
            }
            {
              activePromo?.promoType === PROMO_TYPES.RAFFLE &&
              <>
                {isMobile && <span>&nbsp;·&nbsp;</span>}
                <NewRaffleCounter
                  authToken={auth?.idToken}
                  walletId={wallet?.publicKey?.toString()}
                  promo={activePromo}
                  style={style} />
              </>
            }
            {
              activePromo?.promoType === PROMO_TYPES.LOSERS &&
              <>
                {isMobile && <span>&nbsp;·&nbsp;</span>}
                <PointsCounter
                  promo={activePromo}
                  fragments={fragments}
                  fetchPromo={fetchPromo}
                  authToken={auth?.idToken}
                />
              </>
            }
            {
              activePromo?.promoType === PROMO_TYPES.SCROLL &&
              <>
                {isMobile && <span>&nbsp;·&nbsp;</span>}
                <ScrollCounter
                  authToken={auth?.idToken}
                  walletId={wallet?.publicKey?.toString()}
                  promo={activePromo}
                  style={style}
                />
              </>
            }
          </>
        }
      </div>
    )
  }

  return (
    <>
      <div className={liveFeed ? "social-icons" : "social-icons-hide-live-feed"} style={{ zIndex: 10 }}>
        <div className="d-flex flex-row flex-sm-column justify-content-start align-items-center">
          <div className="mt-3 d-flex flex-column shortcut-row" style={{ zIndex: 1 }}>
            {/* <AnnouncementsWidget style={style} /> */}
            <ButtonsRow />
            <CounterInfoRow />
            {listType === 'recent' && <CoinFlipsList />}
            {listType === 'top' && <TopCoinFlipsList />}
            {
              showProfileModal &&
              <ProfileModal
                show={showProfileModal}
                wallet={wallet}
                profile={profile}
                defaultProfilePicture={community?.profileImageUrl}
                onHide={handleProfileModalClose}
                setProfile={updateProfile}
                styleCss={style}
              />
            }
            {
              showSettingModal &&
              <SettingModal
                show={showSettingModal}
                wallet={wallet}
                profile={profile}
                onHide={handleSettingModalClose}
                styleCss={style} />
            }
            {
              showVerifyUserModal &&
              <VerifyUserModal
                show={showVerifyUserModal}
                onClose={handleVerifyUserModalClose}
                styleCss={style}
              />
            }
            {
              showRankModal &&
              <VolumeRankingModal
                show={showRankModal}
                authToken={auth?.idToken}
                walletId={wallet?.publicKey?.toString()}
                onHide={handleRankModalClose}
                styleCss={style}
              />
            }
            {
              showMyFlipsModal &&
              <MyFlipsModal
                show={showMyFlipsModal}
                styleCss={style}
                onHide={handleMyFlipsModalClose}
              />
            }
            {/* {
              showSlowTPSModal &&
              <SlowTPSModal
                show={showSlowTPSModal}
                styleCss={style}
                onHide={handleSlowTPSModalClose}
              />
            } */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuBar;