import { useContext, useState } from "react";
import { getRewards } from "../../api-smart-contracts/dcf";
import { SideBetsContext } from "../../contexts/side-bets.context";
import { CHALLENGES, constants, opposite } from "../../utils/constants";
import { ResultPage } from "../pages/ResultPage";
import { CoinFlipRewardFailedToast } from "../toasts/CoinFlipRewardFailedToast";

const DCF_ID = CHALLENGES[0].id;

const CoinFlipWizardStepThree = ({
  wallet,
  community,
  currentWinStreak,
  streak,
  winStreakImageUrl,
  amount,
  won,
  side,
  id,
  isFinalized,
  isSuperStreak,
  depositTxn,
  skipReward,
  createdAt,
  coinWalletId,
  whaleMode,
  proMode,
  style,
  onNext,
  onOpenShareModal,
  priorityFee
}: any) => {
  const [rewardLoading, setRewardLoading] = useState(false);
  const { sideBetsState, fetch } = useContext(SideBetsContext);

  const [showClaimRewardErrorToast, setShowClaimRewardErrorToast] = useState(false);
  const handleClaimRewardErrorToastOpen = () => setShowClaimRewardErrorToast(true);
  const handleClaimRewardErrorToastClose = () => setShowClaimRewardErrorToast(false);

  const handleSideBet = () => {
    try {
      if (sideBetsState?.isActive) {
        fetch();
      }
    } catch {
    }
  }

  const claimRewards = async () => {
    setRewardLoading(true);
    handleSideBet();
    try {
      await getRewards(wallet, id, amount, side, priorityFee);
      setRewardLoading(false);
      onNext();
    } catch {
      setRewardLoading(false);
      handleClaimRewardErrorToastOpen();
    }
  }

  return (
    <>
      <ResultPage
        wallet={wallet}
        coinHeadsImageUrl={community?.assets?.coinHeadsImageUrl ?? (!proMode ? constants.BUTTONS.BabyCoin : (style.includes('dark') ? 'https://i.imgur.com/gN0mJx0.gif' : 'https://i.imgur.com/mYUlUD9.gif'))}
        coinTailsImageUrl={community?.assets?.coinTailsImageUrl ?? (!proMode ? constants.BUTTONS.BabyCoinButt : (style.includes('dark') ? 'https://i.imgur.com/4AHibHW.gif' : 'https://i.imgur.com/rtm7GVR.gif'))}
        buttonClaimReward={community?.assets?.buttonClaimReward ?? (whaleMode ? constants.BUTTONS_WHALE.ClaimReward : constants.BUTTONS.ClaimReward)}
        buttonDoubleOrNothing={community?.assets?.buttonDoubleOrNothing ?? (whaleMode ? constants.BUTTONS_WHALE.DoubleOrNothing : constants.BUTTONS.DoubleOrNothing)}
        winningImageUrl={winStreakImageUrl}
        buttonConstants={constants.BUTTONS}
        isWhitelabel={community.id !== DCF_ID}
        currentWinStreak={currentWinStreak}
        streak={streak}
        price={amount}
        winner={won ? side : opposite(side)}
        side={side}
        isFinalized={isFinalized}
        claimRewards={claimRewards}
        goBack={() => { handleSideBet(); onNext() }}
        rewardLoading={rewardLoading}
        isSuperStreak={isSuperStreak}
        coinFlipId={id}
        skipReward={skipReward}
        createdAt={createdAt}
        isOwner={wallet?.publicKey?.toString() === coinWalletId}
        depositTxn={depositTxn}
        proMode={proMode}
        onOpenShareModal={onOpenShareModal} />

      <CoinFlipRewardFailedToast
        code={depositTxn?.slice(0, 4)}
        show={showClaimRewardErrorToast}
        onClose={() => handleClaimRewardErrorToastClose()}
      />
    </>
  );
};

export default CoinFlipWizardStepThree;