import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useEffect, useState } from "react";
import { BASED_COIN, BASED_COIN_INVERTED, CHALLENGES, constants } from "../../utils/constants";
import { loadState } from "../../utils/localStorage";
import { FlipRow } from "../FlipRow";
import { FlipRowPlaceholder } from "../FlipRowPlaceholder";
import { AMLModal } from "../modals/AMLModal";
import { FAQModal } from "../modals/FAQModal";
import { HowToPlayModal } from "../modals/HowToPlayModal";
import { KYCModal } from "../modals/KYCModal";
import { ResponsibleGamingModal } from "../modals/ResponsibleGamingModal";
import { ToCModal } from "../modals/ToCModal";

const DCF_ID = CHALLENGES[0].id;
const COIN_QUESTION_MARK = "https://i.imgur.com/WtWzHUL.png";

const WALLET_NAME_KEY = "walletName";
// const WalletNames = {
//   PHANTOM: "Phantom",
//   SLOPE: "Slope",
//   SOL_FLARE: "Solflare",
//   SOLLET: "Sollet",
//   BACKPACK: "Backpack"
// };

// const SIGNABLE_WALLETS = [
//   WalletNames.PHANTOM,
//   WalletNames.SLOPE,
//   WalletNames.BACKPACK,
//   WalletNames.SOL_FLARE
// ];

const CoinFlipWizardHome = ({
  community,
  style,
  wallet,
  restricted,
  recentCoinFlips,
  loading,
  whaleMode,
  proMode,
  onNext,
}: any) => {
  const [showFAQ, setShowFAQ] = useState(false);
  const handleFAQClose = () => setShowFAQ(false);
  const handleFAQOpen = () => setShowFAQ(true);

  const [walletName, setWalletName] = useState<any>(null);

  const [showHowToPlay, setShowHowToPlay] = useState(false);
  const handleHowToPlayClose = () => setShowHowToPlay(false);
  const handleHowToPlayOpen = () => setShowHowToPlay(true);

  const [showResponsibleGaming, setShowResponsibleGaming] = useState(false);
  const handleResponsibleGamingClose = () => setShowResponsibleGaming(false);
  const handleResponsibleGamingOpen = () => setShowResponsibleGaming(true);

  const [showKYC, setShowKYC] = useState(false);
  const handleKYCClose = () => setShowKYC(false);
  const handleKYCOpen = () => setShowKYC(true);

  const [showAML, setShowAML] = useState(false);
  const handleAMLClose = () => setShowAML(false);
  const handleAMLOpen = () => setShowAML(true);

  const [showToC, setShowToC] = useState(false);
  const handleToCClose = () => setShowToC(false);
  const handleToCOpen = () => setShowToC(true);

  useEffect(() => {
    const key = loadState(WALLET_NAME_KEY);
    if (wallet.connected && key !== walletName) {
      setWalletName(key);
    }
    // eslint-disable-next-line
  }, [wallet]);

  // const onNextClicked = () => {
  //   if (SIGNABLE_WALLETS.includes(walletName)) {
  //     onNext();
  //   } else {
  //     onNext(true);
  //   }
  // };

  const Modals = () => {
    return (
      <>
        {showFAQ && (
          <FAQModal
            show={showFAQ}
            onHide={() => handleFAQClose()}
            wallet={wallet}
            styleCss={style}
          />
        )}
        {showHowToPlay && (
          <HowToPlayModal
            show={showHowToPlay}
            onHide={() => handleHowToPlayClose()}
            styleCss={style}
          />
        )}
        {showResponsibleGaming && (
          <ResponsibleGamingModal
            show={showResponsibleGaming}
            onHide={() => handleResponsibleGamingClose()}
            styleCss={style}
          />
        )}
        {showKYC && (
          <KYCModal
            show={showKYC}
            onHide={() => handleKYCClose()}
            styleCss={style}
          />
        )}
        {showAML && (
          <AMLModal
            show={showAML}
            onHide={() => handleAMLClose()}
            styleCss={style}
          />
        )}
        {showToC && (
          <ToCModal
            show={showToC}
            onHide={() => handleToCClose()}
            styleCss={style}
          />
        )}
      </>
    );
  };

  return (
    <div className="play step1 mt-md-5 pt-md-5 pt-4">
      <Modals />
      {community.id === DCF_ID && (
        <>
          <h3>#1 MOST TRUSTED PLACE TO FLIP</h3>
        </>
      )}
      {community.id !== DCF_ID && (
        <>
          <h2>DOUBLE YOUR SOL</h2>
          <h5>{(community.name + ` Coin Flip`).toUpperCase()}</h5>
        </>
      )}
      <a
        href="https://twitter.com/degencoinflip"
        target="_blank"
        rel="noopener noreferrer"
        className={`text-decoration-none ${community.id !== DCF_ID ? "" : "d-none"}`}
      >
        <h6 className="text-secondary">Powered by DCF Engine</h6>
      </a>

      <div className="form-signin text-center">
        <img
          className="logo rounded-circle"
          src={
            proMode ? (
              (style.includes('dark') ? BASED_COIN_INVERTED : BASED_COIN)
            ) : (community.logoUrl)}
          alt="dcf logo"
          width="256"
          height="256"
        />
        <div className="mb-3"></div>
        {!wallet.connected && (
          <WalletMultiButton
            style={{ marginLeft: "auto", marginRight: "auto" }}
          />
        )}
        {wallet.connected && (
          <>
            {!loading && (
              <>
                {
                  !proMode &&
                  <img
                    onClick={() => onNext()}
                    className="cursor-pointer double-or-nothing-button my-3"
                    src={
                      community?.assets?.buttonDoubleOrNothing ??
                      (whaleMode
                        ? constants.BUTTONS_WHALE.DoubleOrNothing
                        : (restricted ? constants.BUTTONS.FlipACoin : constants.BUTTONS.DoubleOrNothing))
                    }
                    alt="double or nothing"
                    width="100%"
                    height="100%"
                  />
                }
                {
                  proMode &&
                  <button className="fs-3 btn btn-lg btn-dark my-3 font-buttons rounded-0 double-or-nothing-button" onClick={() => onNext()}>
                    DOUBLE OR NOTHING
                  </button>
                }
                <a
                  href="#!"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onNext(true);
                  }}
                >
                  <h6>USE LEDGER</h6>
                </a>
                {/* {walletName === WalletNames.PHANTOM && (
                  <a
                    href="#!"
                    onClick={(ev) => {
                      ev.preventDefault();
                      onNext(true);
                    }}
                  >
                    <h6>USE LEDGER</h6>
                  </a>
                )} 
                {walletName !== WalletNames.PHANTOM && (
                  <h6>CLICK TO SEE OPTIONS</h6>
                )} */}
              </>
            )}
            {loading && (
              <>
                <div className="d-flex justify-content-center py-5">
                  <div className="la-ball-8bits">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>

      <h2 className="mt-5">RECENT PLAYS</h2>
      <div className="form-signin2 text-start">
        <ul className="list-group">
          {recentCoinFlips?.slice(0, 12)?.map((flip: any) => (
            <FlipRow
              type=""
              flip={flip}
              key={flip.id}
              defaultProfilePicture={COIN_QUESTION_MARK}
            ></FlipRow>
          ))}
          {
            !recentCoinFlips?.length && new Array(6).fill(null).map((_, i) => {
              return <FlipRowPlaceholder key={i} n={i} />
            })
          }
        </ul>
      </div>
      <div className="accordion text-center" id="myAccordion">
        <h6 className="mt-3">
          <a
            href="#!"
            onClick={(ev) => {
              ev.preventDefault();
              handleFAQOpen();
            }}
          >
            FAQ
          </a>{" "}
          |&nbsp;
          <a
            href="#!"
            onClick={(ev) => {
              ev.preventDefault();
              handleHowToPlayOpen();
            }}
          >
            HOW TO PLAY
          </a>{" "}
          |&nbsp;
          <a
            href="#!"
            onClick={(ev) => {
              ev.preventDefault();
              handleToCOpen();
            }}
          >
            T&C
          </a>{" "}
          |&nbsp;
          <a
            href="#!"
            onClick={(ev) => {
              ev.preventDefault();
              handleAMLOpen();
            }}
          >
            AML
          </a>{" "}
          |&nbsp;
          <a
            href="#!"
            onClick={(ev) => {
              ev.preventDefault();
              handleKYCOpen();
            }}
          >
            KYC
          </a>{" "}
          |&nbsp;
          <a
            href="#!"
            onClick={(ev) => {
              ev.preventDefault();
              handleResponsibleGamingOpen();
            }}
          >
            RESPONSIBLE GAMING
          </a>
        </h6>
      </div>
      <small className="text-center mb-5">
        <div className="mx-auto text-secondary" style={{ maxWidth: '600px', fontSize: '11px' }}>
          Degen Coin Flip is owned and operated by OG Labs N.V. Registration number: 15754, registere address: Hamchako, Mutsamudu, The Autonomous Island of Anjouan, Union of Comoros. Contact us: degencoinflip@protonmail.com. Degen Coin Flip is licensed and regulated by the Government of the Autonomous Island of Anjouanm Union of Comoros and operates under License No. ALSl-152406032-Fl3. Degen Coin Flip has passed all regulator compliances and is legally authorized to conduct gaming operations for any and all games of chance and wagering.
        </div>
      </small>
    </div>
  );
};

export default CoinFlipWizardHome;
